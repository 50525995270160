import React from 'react'
import styled from 'styled-components'
import { Button, DownloadButton } from '../elements'

import wegiveatruck from '../images/we-give-a-truck-logo.svg'

const WeGiveATruck = () => (
  <WeGiveATruckWrapper>
    <WeGiveATruckLogo src={wegiveatruck} />
    <p>
      We launched a Trucker Lifestyle brand to support our dedication trucking.
      Visit our culture section to learn about the{' '}
      <strong>We Give a Truck</strong>
      <sup>{'\u00AE'}</sup> brand and get some nice swag.
    </p>
    {/* <Button
      modifiers={['outline', 'fullwidth', 'marginbottom']}
      /our-culture"
    >
      Learn About Our Culture
    </Button> */}
    <DownloadButton
      rel="noopener noreferrer"
      href="https://www.wegiveatruck.com"
      target="_blank"
      modifiers={['outline', 'fullwidth', 'marginbottom']}
    >
      Shop We Give a Truck
    </DownloadButton>
  </WeGiveATruckWrapper>
)

export default WeGiveATruck

const WeGiveATruckWrapper = styled.div`
  text-align: left;
`
const WeGiveATruckLogo = styled.img`
  margin: 0 auto;
  width: 284px;
  display: block;
  margin-bottom: 2rem;
`

import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'
import History from '../components/History'
import WeGiveATruck from '../components/WeGiveATruck'

import {
  above,
  below,
  Zone,
  Box,
  Button,
  LargeP,
  SidebarLayout,
  PageHeader,
  richGrey,
  primary,
  BenefitsSidebarList,
  BenefitsItem,
} from '../elements'

import firsttruck from '../images/history/ddtsi-first-refrigerated-truck.png'

const AboutPage = ({ data }) => {
  const postNode = {
    title: `About - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <Helmet>
        <title>{`About - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="about" customTitle />

      <Zone modifiers={['blank', 'center', 'short']} hero>
        <div className="zone-content">
          <h2>Driving Dependable Service</h2>
          <h3>
            With innovative leadership and world class services, we will deliver
            beyond expectations.
          </h3>
          <Button to="/careers">Become Part of the Team</Button>
        </div>
      </Zone>

      <Container>
        {/* <PageTitle>About</PageTitle> */}
        <SidebarLayout modifiers={['light']}>
          <div>
            <PageHeader>About Us</PageHeader>
            <LargeP>
              D&D was founded in the small farming community of Gooding,
              Idaho–placing its roots and ethics with the families and hands
              that feed America. We are proud of our small town values and find
              Idaho as a wonderful place to raise a family and grow a strong
              business.
            </LargeP>
            <p>
              While dependable service is a priority, we could not meet those
              standards without the relationship we have developed both
              internally and externally over the years. A genuine connection and
              relationship has always proven that if you have a priority for
              people, the profit will follow.
            </p>
            <p>
              D&D is proud to operate a fleet of late-model tractor/trailers
              throughout the 48 United States, equipped with the most advanced
              reefer units designed for maximum cargo protection. With
              innovative leadership and services that are world class, we will
              deliver beyond expectations. While our approach is forward
              thinking, proactive and flexible in delivering whatever is
              required, we would like to give credit where credit is due. Real
              success comes in the company we keep. We believe that respect and
              trust in who we work with will drive accomplishment and prosperity
              for everyone. A genuine connection and relationship has always
              proven that if you have a priority for people, the profit will
              follow.
            </p>
          </div>
          <div className="benefits-sidebar">
            <WeGiveATruck />
          </div>
        </SidebarLayout>
      </Container>

      <HistoryZone>
        <Container>
          <img src={firsttruck} alt="D&D's first truck" />
          <h2>Our History</h2>
          <LargeP>
            What started in a garage that was converted for Darlene to a home
            office, quickly mushroomed into a thriving brokerage company.
          </LargeP>
          <p>
            In 1980, Calvin & Darlene Kuntz founded D&D Brokers in Gooding,
            Idaho. D&D quickly grew a loyal following of trucks and customer
            through honesty and commitment to customer service. Darlene would
            never lie and kept her promise to load carriers even when times
            where tight. It was not long before word spread in the trucking
            industry and a multitude of dispatchers from various trucking firms
            would contact her with overflow loads. Customers loved her
            commitment. Darlene would work all night, if necessary, just to load
            one truck. If there was a breakdown or a delay in transit, she would
            be on the phone immediately with the customer to notify them. She
            only had two phone lines, a typewriter and no fax. Being new to
            transportation she did not necessarily follow conventional methods
            known to the industry. Quick pay, attention to detail, honesty and
            great communication all contributed to a trusted and loyal
            following. Even difficult customers to break into would eventually
            become loyal through her consistency and persistence.
          </p>
          <p>
            In 1981 the small brokerage had grown into a thriving business and
            Calvin joined the team and moved into their first office in downtown
            Gooding, Idaho. It was not long before customer demand outweighed
            staff. Calvin and Darlene decided to hire their first dispatch and
            load broker in 1984. As the loyal group of dedicated carriers
            continued to blossom, customers who only trusted D&D desired a
            dedicated fleet. This lead to their first truck purchases in early
            1984. Within the first years they quickly had a fleet of 5 trucks
            and several large customers. In 1986 they had grown into a staff of
            5 that serviced the 48 continental United States. Their transport
            fleet specialized in refrigerated transport, hauling everything from
            sensitive produce to frozen french fries. The brokerage division
            handled everything from dry goods like expensive sugar beat seed and
            carpet to frozen specialties.
          </p>
          <p>
            Today D&D has a stable fleet of 88 state of the art tractors, 125
            cutting edge refrigerated trailers and 140 employees. They continue
            to maintain their family environment, commitment to customer service
            and steady growth. Learn more about D&D through the Services,
            Transportation, Corporate Leadership pages as well as their blog and
            news areas.
          </p>
        </Container>
      </HistoryZone>

      <Container>
        <StyledSidebarLayout>
          <div>
            <h2>Health & Safety</h2>
            <LargeP>
              Safety is D&D's number one priority. We believe safety is an
              attitude and a choice. D&D drivers and employees are required to
              comply with all rules set by the Department of Transportation
              (DOT), and all company safety rules. We are very proud of our
              safety programs here at D&D and the accomplishments that we have
              achieved.
            </LargeP>
            <p>
              We have periodic safety meetings with drivers and operations to
              show them how the interaction they have with one another affects
              the mental part of the job and how that affects safety. All of our
              new hires are given extensive training in safety and the
              importance it has on all aspects of the company. Our goal is to
              stress and re-access the problems that are faced out on the road
              in the day-to-day driving environment. Our drivers have 24/7, 365
              days per year access to our safety department and/or to our upper
              level management to discuss any safety related issues that may be
              foreseen or occurring at the present time.
            </p>
            <p>
              D&D has a fully operational maintenance shop, equipped to repair
              all aspects of both trucks and trailers; this includes everything
              from tires to complete overhauls, bodywork, windows, etc. We
              maintain all equipment to the highest standards, giving our
              drivers a safe piece of equipment so they can concentrate on
              driving and dealing with the motoring public and not worrying
              about mechanical failures. Through the continued effort of all the
              employees at D&D we presently have a superb safety rating and we
              feel confident in the fact that we have one of the safest fleets
              out on the road.
            </p>
          </div>
          <div className="benefits-sidebar">
            <h4>Health & Safety at a Glance</h4>
            <BenefitsSidebarList>
              <BenefitsItem>
                Investment in aerodynamics truck and trailers
              </BenefitsItem>
              <BenefitsItem>
                Driver training and incentives for reduced fuel consumption
              </BenefitsItem>
              <BenefitsItem>Latest diesel engine technology</BenefitsItem>
              <BenefitsItem>
                Idle reduction strategies such as Pre-Pass for scales/weigh
                stations
              </BenefitsItem>
              <BenefitsItem>
                Reduced fuel consumption with direct fired heaters
              </BenefitsItem>
              <BenefitsItem>
                Speed management (trucks governed at 65 MPH)
              </BenefitsItem>
              <BenefitsItem>
                Tire technology for automatic tire inflation
              </BenefitsItem>
              <BenefitsItem>Low-viscosity lubricants</BenefitsItem>
              <BenefitsItem>Improved freight logistics</BenefitsItem>
            </BenefitsSidebarList>
          </div>
        </StyledSidebarLayout>
        <StyledSidebarLayout>
          <div className="benefits-sidebar left-side">
            <h4>Social Responsibility at a Glance</h4>
            <BenefitsSidebarList>
              <BenefitsItem>
                Investment in aerodynamics truck and trailers
              </BenefitsItem>
              <BenefitsItem>
                Driver training and incentives for reduced fuel consumption
              </BenefitsItem>
              <BenefitsItem>Latest diesel engine technology</BenefitsItem>
              <BenefitsItem>
                Idle reduction strategies such as Pre-Pass for scales/weigh
                stations
              </BenefitsItem>
              <BenefitsItem>
                Reduced fuel consumption with direct fired heaters
              </BenefitsItem>
              <BenefitsItem>
                Speed management (trucks governed at 65 MPH)
              </BenefitsItem>
              <BenefitsItem>
                Tire technology for automatic tire inflation
              </BenefitsItem>
              <BenefitsItem>Low-viscosity lubricants</BenefitsItem>
              <BenefitsItem>Improved freight logistics</BenefitsItem>
            </BenefitsSidebarList>
          </div>
          <div>
            <h2>Social Responsibility</h2>
            <LargeP>
              At D&D, we take our environmental stewardship seriously. That’s
              why, over the past decade, we have invested in technology and
              solutions that reduce overhead and improve productivity for our
              customers while lowering greenhouse gas emissions.
            </LargeP>
            <p>
              D&D has also been a proud member of the industry-leading U.S. EPA
              SmartWay<sup>{'\u00AE'}</sup> Transport Partnership since 2009.
              The SmartWay Program, a market-driven initiative working to
              advance freight sector efficiency, enables companies across the
              supply chain to make informed transportation choices. By providing
              a consistent set of tools and information, SmartWay empowers its
              partners to exchange performance data in ways that protect the
              environment, enhance our nation’s energy security, and foster
              economic vitality.
            </p>
            <p>
              D&D represents the top one percent of environmental performers of
              the SmartWay’s 3000+ partners. We are proud to be honored by the
              U.S. Environmental Protection Agency as one of 39 carriers leading
              our industry in freight supply chain environmental performance and
              energy efficiency.
            </p>
          </div>
        </StyledSidebarLayout>
      </Container>
    </Layout>
  )
}

export default AboutPage

const BioBox = styled(Box)`
  ${below.lg`
    flex: 0 0 100%;
  `};
`
const LeadershipP = styled.p`
  ${above.lg`
    width: 60%;
  `}
`
const BioImg = styled.img`
  background: ${richGrey};
  max-width: 100%;
  height: auto;
  object-fit: contain;
`

const HistoryZone = styled.section`
  padding: 3rem 0;
  margin-bottom: 10px;
  background: white;
  position: relative;
  img {
    float: left;
    width: 700px;
    margin-right: 30px;
    ${below.lg`
      width: 100%;
      margin-bottom: 2rem;
    `}
  }
`

const StyledSidebarLayout = styled(SidebarLayout)`
  .benefits-sidebar li {
    margin-left: 2rem;
    &:before {
      font-family: 'Material Icons';
      font-size: 24px;
      line-height: 1;
      content: 'done';
      color: ${primary};
      padding-right: 0.5rem;
      margin-left: -2rem;
    }
  }
  .benefits-sidebar {
    background: white;
  }
  .left-side {
    margin-left: 0;
    margin-bottom: 2rem;
    ${above.lg`
      margin-right: 2rem;
    `}
  }
`

import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { primary, ltGrey } from '../elements'
import { sans } from '../utilities'

import firsttruck from '../images/history/ddtsi-first-refrigerated-truck.png'
import kenworthtruck from '../images/history/ddtsi-bullet-nose-kenworth.jpg'
import ddtsisunset from '../images/history/ddtsi-sunset.jpg'
import brownkenworth from '../images/history/brown-kenworth.jpg'
import peterbilttruck from '../images/history/peterbilt-trucks.jpg'

const data = [
  {
    coverimage: firsttruck,
    year: '1980',
    heading: 'The Early Years',
    body:
      'In 1980, Calvin & Darlene Kuntz founded D&D Brokers in Gooding, Idaho. What started in a garage that was converted for Darlene to a home office, quickly mushroomed into a thriving brokerage company. D&D quickly grew a loyal following of trucks and customer through honesty and commitment to customer service. Darlene would never lie and kept her promise to load carriers even when times where tight. It was not long before word spread in the trucking industry and a multitude of dispatchers from various trucking firms would contact her with overflow loads. Customers loved her commitment. Darlene would work all night, if necessary, just to load one truck. If there was a breakdown or a delay in transit, she would be on the phone immediately with the customer to notify them. She only had two phone lines, a typewriter and no fax. Being new to transportation she did not necessarily follow conventional methods known to the industry. Quick pay, attention to detail, honesty and great communication all contributed to a trusted and loyal following. Even difficult customers to break into would eventually become loyal through her consistency and persistence.',
  },
  {
    coverimage: kenworthtruck,
    year: '1981',
    heading: 'The Beginning of a Transportation Company',
    body:
      'In 1981 the small brokerage had grown into a thriving business and Calvin joined the team and moved into their first office in downtown Gooding, Idaho. It was not long before customer demand outweighed staff. Calvin and Darlene decided to hire their first dispatch and load broker in 1984. As the loyal group of dedicated carriers continued to blossom, customers who only trusted D&D desired a dedicated fleet. This lead to their first truck purchases in early 1984. Within the first years they quickly had a fleet of 5 trucks and several large customers. In 1986 they had grown into a staff of 5 that serviced the 48 continental United States. Their transport fleet specialized in refrigerated transport, hauling everything from sensitive produce to frozen french fries. The brokerage division handled everything from dry goods like expensive sugar beat seed and carpet to frozen specialties.',
  },
  {
    coverimage: brownkenworth,
    year: '1986',
    heading: 'The Growing Years',
    body:
      '1986 marked the year of significant growth. D&D had quickly outgrown three office spaces and committed to purchase their largest facility yet, a 3000 square foot office with a two bay shop on the southside of Gooding, Idaho. They now had one single location for their dispatch, brokerage, accounting and service shop.',
  },
  {
    coverimage: ddtsisunset,
    year: '1990',
    heading: 'Cementing a Future',
    body:
      'By 1990, D&D owned a fleet of 45 trucks and employed 75 personnel. D&D sought steady growth and continued to maintain a solid customer base. D&D expanded their corporate office with a major renovation building a state-of-the-art maintenance facility expanding their shop to five bays with a wash rack and storage.',
  },
  {
    coverimage: peterbilttruck,
    year: '2000',
    heading: 'Continuing a Legacy',
    body:
      'Today D&D has a stable fleet of 88 state of the art tractors, 125 cutting edge refrigerated trailers and 140 employees. They continue to maintain their family environment, commitment to customer service and steady growth. Learn more about D&D through the Services, Transportation, Corporate Leadership pages as well as their blog and news areas.',
  },
]

export default class History extends Component {
  state = {
    active: 0,
  }

  prev = () => {
    const { active } = this.state
    if (active > 0) {
      this.setState({
        active: active - 1,
      })
    }
  }

  next = () => {
    const { active } = this.state
    if (active < data.length - 1) {
      this.setState({
        active: active + 1,
      })
    }
  }

  render() {
    const { active } = this.state
    return (
      <TimelineZone>
        <TimelineH>History</TimelineH>
        <TrackWrapper>
          <Track>
            <TrackLineContainer>
              <TrackLine />
              <TrackDots
                style={{
                  transform: `translate3d(${active * 32 * -1}%, 0, 0)`,
                }}
              >
                {data.map((item, index) => (
                  <TrackDotWrapper>
                    <TrackDotsYear active={active === index}>
                      {item.year}
                    </TrackDotsYear>
                    <TrackDot
                      onClick={() =>
                        this.setState({
                          active: index,
                        })
                      }
                      key={index}
                    />
                  </TrackDotWrapper>
                ))}
              </TrackDots>
            </TrackLineContainer>
            <TrackButtons>
              <TrackButton onClick={this.prev}>{left}</TrackButton>
              <TrackButton onClick={this.next}>{right}</TrackButton>
            </TrackButtons>
          </Track>
        </TrackWrapper>
        <SlideContainer>
          <SlideTrack
            style={{
              width: data.length * 100 + '%',
              transform: 'translate3d(' + active * -100 + 'vw, 0, 0)',
            }}
          >
            {data.map(item => (
              <Slide key={item.year}>
                <div className="img">
                  <img src={item.coverimage} />
                </div>
                <div>
                  <h3>{item.year}</h3>
                  <h4>{item.heading}</h4>
                  <p>{item.body}</p>
                </div>
              </Slide>
            ))}
          </SlideTrack>
        </SlideContainer>
      </TimelineZone>
    )
  }
}

const TimelineZone = styled.section`
  padding: 3rem 0;
  margin-bottom: 10px;
  background: white;
  position: relative;
`

const TrackWrapper = styled.div`
  padding: 0 6%;
  margin: 4rem 0 5rem;
`

const Track = styled.div`
  position: relative;
`
const TrackLineContainer = styled.div`
  position: relative;
  overflow-x: hidden;
  padding-top: 70px;
`

const TrackDots = styled.div`
  display: flex;
  left: 0;
  top: -11px;
  width: 100%;
  z-index: 20;
  position: relative;
  transition: 0.3s ease all;
`

const TrackDotsYear = styled.div`
  background: ${primary};
  color: white;
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 40px;
  font-weight: bold;
  opacity: 0;
  transition: 0.3s ease all;
  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: ${primary};
    left: 50%;
    bottom: -6px;
    transform: translateX(-50%) rotate(45deg);
    z-index: -1;
  }
  ${({ active }) =>
    active &&
    css`
      opacity: 1;
    `}
`

const TrackDotWrapper = styled.div`
  margin: 0 15%;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
`

const TrackDot = styled.div`
  height: 24px;
  width: 24px;
  background: ${primary};
  border-radius: 50%;
`

const TrackLine = styled.div`
  height: 2px;
  background: ${ltGrey};
`
const TimelineH = styled.h2`
  text-align: center;
`
const TrackButtons = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  bottom: 12px;
  width: 100%;
  z-index: 20;
  pointer-events: none;
`

const SlideContainer = styled.div`
  overflow: hidden;
`
const SlideTrack = styled.div`
  display: flex;
  transition: 0.3s ease all;
`

const TrackButton = styled.button`
  width: 30px;
  height: 30px;
  display: flex;
  border: none;
  pointer-events: all;
  background: ${primary};
  outline: none;
  cursor: pointer;
`
const Slide = styled.div`
  display: flex;
  width: 100vw;
  padding: 0 3%;
  .img {
    width: 50%;
    margin-right: 30px;
    flex-grow: 0;
    flex-shrink: 0;
  }
  h3 {
    color: ${primary};
  }
  h4 {
    color: ${primary};
    /* ${sans()}; */
  }
`

const left = (
  <svg
    width="15"
    viewBox="0 0 448 438"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M257.5 408.1L235.3 430.3C225.9 439.7 210.7 439.7 201.4 430.3L7 236C-2.4 226.6 -2.4 211.4 7 202.1L201.4 7.70002C210.8 -1.69998 226 -1.69998 235.3 7.70002L257.5 29.9C267 39.4 266.8 54.9 257.1 64.2L136.6 179H424C437.3 179 448 189.7 448 203V235C448 248.3 437.3 259 424 259H136.6L257.1 373.8C266.9 383.1 267.1 398.6 257.5 408.1Z"
      fill="white"
    />
  </svg>
)

const right = (
  <svg
    width="15"
    viewBox="0 0 448 438"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M190.5 29.9L212.7 7.70002C222.1 -1.69998 237.3 -1.69998 246.6 7.70002L441 202C450.4 211.4 450.4 226.6 441 235.9L246.6 430.3C237.2 439.7 222 439.7 212.7 430.3L190.5 408.1C181 398.6 181.2 383.1 190.9 373.8L311.4 259H24C10.7 259 0 248.3 0 235V203C0 189.7 10.7 179 24 179H311.4L190.9 64.2C181.1 54.9 180.9 39.4 190.5 29.9Z"
      fill="white"
    />
  </svg>
)
